import React, { useEffect } from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Img from "gatsby-image";

import "../styles/blog.scss";

import resCal from "../images/res-cal.png";
import SEO from "../components/seo";

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
  pageContext,
}: {
  data: any;
  pageContext: any;
}) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  const { previous, next } = pageContext;

  // useEffect(() => {
  //   console.log(frontmatter);
  // }, []);

  return (
    <Layout>
      <SEO
        title={frontmatter.title}
        description={frontmatter.seoDescription}
        // keywords={frontmatter.keywords}
        lang="en-gb"
        // image={frontmatter.featuredImage}
      />
      <div className="blog-wrapper">
        <div className="w-max-nf">
          <div className="blog-post-container">
            <div className="blog-post">
              <Img
                fluid={frontmatter.featuredImage.childImageSharp.fluid}
                className="image-fit"
              />
              {frontmatter.pressRelease == true ? (
                <>
                  <p>FOR IMMEDIATE RELEASE</p>
                  <h3 style={{ textTransform: "capitalize" }}>
                    {frontmatter.date} || {frontmatter.author}
                  </h3>
                  <h1>{frontmatter.title}</h1>
                </>
              ) : (
                <>
                  <h1>{frontmatter.title}</h1>
                  <h3 style={{ textTransform: "capitalize" }}>
                    {frontmatter.date} || {frontmatter.author}
                  </h3>
                </>
              )}

              <div
                className="blog-post-content"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
          </div>
          <div className="blog-sidebar">
            <img
              src={resCal}
              className="image-fit"
              style={{ maxWidth: "100px" }}
            />
            <h3>Recent Posts</h3>
            <ul>
              <li>
                <Link to="/blog/the-end-of-perpetual-licenses-and-the-start-of-new-way-to-vmware">
                  Exploring VMware’s updated licensing structures: The end of
                  perpetual licenses and the start of new way to VMware
                </Link>
              </li>
              <li>
                <Link to="/blog/navigating-the-broadcom-acquisition-and-its-impact-on-oustomers">
                  VMware's New Chapter: Navigating the Broadcom Acquisition and
                  Its Impact On Customers
                </Link>
              </li>
              <li>
                <Link to="/blog/the-critical-considerations-and-solutions-for-data-sovereignty">
                  The critical considerations (and solutions) for data
                  sovereignty
                </Link>
              </li>
              <li>
                <Link to="/blog/six-strategies-to-mitigate-vmware-vulnerabilities">
                  Six strategies to mitigate VMware vulnerabilities
                </Link>
              </li>

              <li>
                <Link to="/blog/vmware-vulnerabilities-in-2023">
                  VMware Vulnerabilities in 2023: What you need to know and do.
                </Link>
              </li>
              <li>
                <Link to="/blog/what-are-vmware-cves-and-how-do-they-impact-your-business">
                  What are VMware CVEs and how do they impact your business?
                </Link>
              </li>
              <li>
                <Link to="/blog/the-critically-needed-vmware-skills-in-south-africa-available-from-strategix-technology-solutions">
                  The critically needed VMware skills in South Africa, available
                  from Strategix Technology Solutions
                </Link>
              </li>
              <li>
                <Link to="/blog/vmware-tanzu-mission-control-essentials">
                  VMware Tanzu Mission Control Essentials: A gamechanger for
                  Kubernetes cluster management
                </Link>
              </li>
              <li>
                <Link to="/blog/the-skills-the-opportunities-and-the-capabilities">
                  VMware Tanzu: The skills, the opportunities and the
                  capabilities
                </Link>
              </li>
              <li>
                <Link to="/blog/is-your-business-ransomware-ready">
                  Is your business ransomware ready?
                </Link>
              </li>
              <li>
                <Link to="/blog/is-your-business-ready-for-the-cloud-native-era">
                  Is Your Business Ready For The Cloud Native Era?
                </Link>
              </li>

              <li>
                <Link to="/blog/data-protection-as-a-service">
                  Data protection as a service: The antidote to sophisticated
                  cyber threats?
                </Link>
              </li>
              <li>
                <Link to="/blog/ransomware-protection">
                  Ransomware protection: the forgotten line of defense in data
                  protection strategies.
                </Link>
              </li>
              <li>
                <Link to="/blog/transition-to-cloud-with-azure-vmware-solution">
                  How the Azure VMware Solution enables a seamless transition
                  into the cloud.
                </Link>
              </li>
              <li>
                <Link to="/blog/strategix-adp-bring-rubrik-data-protection-to-africa">
                  Strategix partners with Assured Data Protection to deliver
                  Rubrik enterprise-grade data protection to Africa.
                </Link>
              </li>
              <li>
                <Link to="/blog/strategix-technology-solutions-launches-new-website">
                  Strategix Technology Solutions Launches New Website.
                </Link>
              </li>
              <li>
                <Link to="/blog/cloudy-innovation">
                  Cloudy with a chance of innovation
                </Link>
              </li>
              <li>
                <Link to="/blog/embracing-digital-transformation">
                  Embracing digital transformation as platform for growth
                </Link>
              </li>
              <li>
                <Link to="/blog/remote-working">
                  Remote working is here to stay but is your company protected
                </Link>
              </li>
              <li>
                <Link to="/blog/best-kept-secret-ict">
                  We are the best kept secret in SAs ICT industry
                </Link>
              </li>
              <li>
                <Link to="/blog/high-cost-ransomware-breach-really-costs-company">
                  The high cost of ransomware: what a breach really costs your
                  company
                </Link>
              </li>

              <li>
                <Link to="/blog/data-resilience-value-data-backup-recovery">
                  Data resilience: The value of data backup and recovery
                </Link>
              </li>
              <li>
                <Link to="/blog/the-rising-threat-of-ransomware">
                  Held to ransom: the rising threat of ransomware (Part 01).
                </Link>
              </li>
              <li>
                <Link to="/blog/held-to-ransom-the-rising-ransomware-threat-part2">
                  Held to ransom: the rising ransomware threat (Part 02).
                </Link>
              </li>

              <li>
                <Link to="/blog/data-protection-as-a-service">
                  Data protection as a service: The antidote to sophisticated
                  cyber threats?
                </Link>
              </li>
              <li>
                <Link to="/blog/the-anatomy-of-a-south-african-ransomware-attack">
                  The anatomy of a South African ransomware attack
                </Link>
              </li>
              <li>
                <Link to="/blog/the-top-5-cloud-trends-of-2023">
                  The Top 5 Cloud Trends of 2023 (so far)
                </Link>
              </li>
              <li>
                <Link to="/blog/creating-sustainable-digital-ecosystems">
                  Creating sustainable digital ecosystems with ethical
                  partnerships
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        seoDescription
        author
        pressRelease
      }
    }
  }
`;
